"use strict";
Object.defineProperty(exports, "__esModule", {
  value: !0
}), exports.styled = exports.setup = exports.keyframes = exports.glob = exports.extractCss = exports.css = void 0;
let e = {
    data: ""
  },
  t = t => "object" == typeof window ? ((t ? t.querySelector("#_goober") : window._goober) || Object.assign((t || document.head).appendChild(document.createElement("style")), {
    innerHTML: " ",
    id: "_goober"
  })).firstChild : t || e,
  r = e => {
    let r = t(e),
      s = r.data;
    return r.data = "", s
  },
  l = /(?:([\u0080-\uFFFF\w-%@]+) *:? *([^{;]+?);|([^;}{]*?) *{)|(}\s*)/g,
  a = /\/\*[^]*?\*\/|\s\s+|\n/g,
  n = (e, t) => {
    let r = "",
      s = "",
      o = "";
    for (let l in e) {
      let a = e[l];
      "@" == l[0] ? "i" == l[1] ? r = l + " " + a + ";" : s += "f" == l[1] ? n(a, l) : l + "{" + n(a, "k" == l[1] ? "" : t) + "}" : "object" == typeof a ? s += n(a, t ? t.replace(/([^,])+/g, (e => l.replace(/(^:.*)|([^,])+/g, (t => /&/.test(t) ? t.replace(/&/g, e) : e ? e + " " + t : t)))) : l) : null != a && (l = l.replace(/[A-Z]/g, "-$&").toLowerCase(), o += n.p ? n.p(l, a) : l + ":" + a + ";")
    }
    return r + (t && o ? t + "{" + o + "}" : o) + s
  },
  o = {},
  s = e => {
    if ("object" == typeof e) {
      let t = "";
      for (let r in e) t += r + s(e[r]);
      return t
    }
    return e
  },
  c = (e, t, r, p, c) => {
    let u = s(e),
      i = o[u] || (o[u] = (e => {
        let t = 0,
          r = 11;
        for (; t < e.length;) r = 101 * r + e.charCodeAt(t++) >>> 0;
        return "go" + r
      })(u));
    if (!o[i]) {
      let t = u !== e ? e : (e => {
        let t, r = [{}];
        for (; t = l.exec(e.replace(a, ""));) t[4] ? r.shift() : t[3] ? r.unshift(r[0][t[3]] = r[0][t[3]] || {}) : r[0][t[1]] = t[2];
        return r[0]
      })(e);
      o[i] = n(c ? {
        ["@keyframes " + i]: t
      } : t, r ? "" : "." + i)
    }
    return ((e, t, r) => {
      -1 == t.data.indexOf(e) && (t.data = r ? e + t.data : t.data + e)
    })(o[i], t, p), i
  },
  i = (e, t, r) => e.reduce(((e, s, o) => {
    let l = t[o];
    if (l && l.call) {
      let e = l(r),
        t = e && e.props && e.props.className || /^go/.test(e) && e;
      l = t ? "." + t : e && "object" == typeof e ? e.props ? "" : n(e, "") : !1 === e ? "" : e
    }
    return e + s + (null == l ? "" : l)
  }), "");

function u(e) {
  let r = this || {},
    s = e.call ? e(r.p) : e;
  return c(s.unshift ? s.raw ? i(s, [].slice.call(arguments, 1), r.p) : s.reduce(((e, t) => Object.assign(e, t && t.call ? t(r.p) : t)), {}) : s, t(r.target), r.g, r.o, r.k)
}
exports.extractCss = r, exports.css = u;
let p, d, f, g = u.bind({
    g: 1
  }),
  b = u.bind({
    k: 1
  });

function h(e, t, r, s) {
  n.p = t, p = e, d = r, f = s
}

function m(e, t) {
  let r = this || {};
  return function() {
    let s = arguments;

    function o(l, n) {
      let a = Object.assign({}, l),
        c = a.className || o.className;
      r.p = Object.assign({
        theme: d && d()
      }, a), r.o = / *go\d+/.test(c), a.className = u.apply(r, s) + (c ? " " + c : ""), t && (a.ref = n);
      let i = e;
      return e[0] && (i = a.as || e, delete a.as), f && i[0] && f(a), p(i, a)
    }
    return t ? t(o) : o
  }
}
exports.glob = g, exports.keyframes = b, exports.setup = h, exports.styled = m;
