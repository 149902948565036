"use strict";
Object.defineProperty(exports, "__esModule", {
  value: !0
}), exports.Node = void 0;
const mobx_state_tree_1 = require("mobx-state-tree"),
  nanoid_1 = require("nanoid"),
  store_1 = require("./store"),
  page_model_1 = require("./page-model"),
  group_model_1 = require("./group-model");
exports.Node = mobx_state_tree_1.types.model("Node", {
  id: mobx_state_tree_1.types.identifier,
  type: "none",
  name: "",
  opacity: 1,
  custom: mobx_state_tree_1.types.frozen(),
  visible: !0,
  selectable: !0,
  removable: !0,
  alwaysOnTop: !1,
  showInExport: !0
}).preProcessSnapshot((e => {
  for (var t in e) null === e[t] && (e[t] = void 0);
  return e
})).postProcessSnapshot((e => {
  const t = Object.assign({}, e),
    o = {};
  for (var r in t) "_" !== r[0] && (o[r] = e[r]);
  return o
})).views((e => ({
  get locked() {
    return !(e.draggable || e.contentEditable || e.styleEditable || e.resizable)
  },
  get page() {
    return (0, mobx_state_tree_1.getParentOfType)(e, page_model_1.Page)
  },
  get store() {
    return (0, mobx_state_tree_1.getParentOfType)(e, store_1.Store)
  },
  get top() {
    let t = e;
    for (;;) {
      if (!(0, mobx_state_tree_1.hasParentOfType)(t, group_model_1.GroupElement)) return t;
      t = (0, mobx_state_tree_1.getParentOfType)(t, group_model_1.GroupElement)
    }
  },
  get parent() {
    return (0, mobx_state_tree_1.hasParentOfType)(e, group_model_1.GroupElement) ? (0, mobx_state_tree_1.getParentOfType)(e, group_model_1.GroupElement) : (0, mobx_state_tree_1.hasParentOfType)(e, page_model_1.Page) ? (0, mobx_state_tree_1.getParentOfType)(e, page_model_1.Page) : (0, mobx_state_tree_1.hasParentOfType)(e, store_1.Store) ? (0, mobx_state_tree_1.getParentOfType)(e, store_1.Store) : null
  },
  get zIndex() {
    return e.parent.children.indexOf(e)
  }
}))).actions((e => ({
  toJSON: () => Object.assign({}, (0, mobx_state_tree_1.getSnapshot)(e))
}))).actions((e => ({
  clone(t = {}, {
    skipSelect: o = !1
  } = {}) {
    const r = JSON.parse(JSON.stringify(e.toJSON()));
    return t.id = t.id || (0, nanoid_1.nanoid)(10), (0, group_model_1.forEveryChild)(r, (e => {
      e.id = (0, nanoid_1.nanoid)(10)
    })), Object.assign(r, t), e.page.addElement(r, {
      skipSelect: o
    })
  },
  set(t) {
    Object.assign(e, t)
  },
  moveUp() {
    e.page.moveElementsUp([e.id])
  },
  moveTop() {
    e.page.moveElementsTop([e.id])
  },
  moveDown() {
    e.page.moveElementsDown([e.id])
  },
  moveBottom() {
    e.page.moveElementsBottom([e.id])
  },
  setZIndex(t) {
    e.parent.setElementZIndex(e.id, t)
  },
  beforeDestroy() {}
})));
