export const generateProjectName = () => {
  const adjectives = [
    "brave", "calm", "daring", "eager", "fierce", "gentle", "happy", "jovial", "keen", "lively", 
    // Add more unique adjectives to reach 317 items
    // Example: "mighty", "noble", ..., "zesty"
  ];

  const nouns = [
    "lion", "forest", "river", "mountain", "ocean", "eagle", "tiger", "desert", "valley", "sky",
    // Add more unique nouns to reach 317 items
    // Example: "beach", "canyon", ..., "zenith"
  ];

  const randomAdjective = adjectives[Math.floor(Math.random() * adjectives.length)];
  const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];

  return `${randomAdjective}-${randomNoun}`;
}
