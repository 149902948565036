"use strict";
async function downloadFile(e, o) {
  const t = await (await fetch(e)).blob(),
    d = document.createElement("a"),
    n = URL.createObjectURL(t);
  d.href = n, d.download = o, document.body.appendChild(d), d.click(), setTimeout((function() {
    document.body.removeChild(d), window.URL.revokeObjectURL(n)
  }), 0)
}
Object.defineProperty(exports, "__esModule", {
  value: !0
}), exports.downloadFile = void 0, exports.downloadFile = downloadFile;
