"use strict";
Object.defineProperty(exports, "__esModule", {
  value: !0
}), exports.registerAnimation = exports.animate = exports.animations = void 0;
const math_1 = require("./math"),
  MOVE_DIRECTION = {
    right: {
      from: {
        x: -200
      },
      to: {
        x: 0
      }
    },
    left: {
      from: {
        x: 200
      },
      to: {
        x: 0
      }
    },
    up: {
      from: {
        y: 200
      },
      to: {
        y: 0
      }
    },
    down: {
      from: {
        y: -200
      },
      to: {
        y: 0
      }
    },
    "bottom-right": {
      from: {
        x: -200,
        y: -200
      },
      to: {
        x: 0,
        y: 0
      }
    },
    "bottom-left": {
      from: {
        x: 200,
        y: -200
      },
      to: {
        x: 0,
        y: 0
      }
    },
    "top-right": {
      from: {
        x: -200,
        y: 200
      },
      to: {
        x: 0,
        y: 0
      }
    },
    "top-left": {
      from: {
        x: 200,
        y: 200
      },
      to: {
        x: 0,
        y: 0
      }
    }
  };
exports.animations = {
  fade: ({
    dTime: t,
    element: e,
    animation: i
  }) => {
    const o = t / i.duration;
    return "enter" === i.type ? {
      opacity: o * e.opacity
    } : {
      opacity: (1 - o) * e.opacity
    }
  },
  rotate: ({
    dTime: t,
    element: e,
    animation: i
  }) => {
    const o = t / i.duration * 360;
    return (0, math_1.rotateAroundCenter)({
      x: e.x,
      y: e.y,
      width: e.width,
      height: e.height,
      rotation: e.rotation
    }, o)
  },
  blink: ({
    dTime: t,
    element: e,
    animation: i
  }) => {
    const o = i.duration,
      n = t % o / (o / 2),
      r = n <= 1 ? n : 2 - n;
    return {
      opacity: e.opacity * r
    }
  },
  bounce: ({
    dTime: t,
    element: e,
    animation: i
  }) => {
    const o = i.duration,
      n = o / 2,
      r = {},
      a = {
        x: e.x + e.width / 3,
        y: e.y + e.height / 3,
        width: e.width / 3,
        height: e.height / 3,
        fontSize: e.fontSize / 3
      },
      m = {
        x: e.x,
        y: e.y,
        width: e.width,
        height: e.height,
        fontSize: e.fontSize
      },
      h = t % o / n,
      d = h <= 1 ? h : 2 - h;
    for (let t in a) {
      const e = a[t],
        i = m[t] - e;
      r[t] = e + d * i
    }
    return r
  },
  move: ({
    dTime: t,
    element: e,
    animation: i
  }) => {
    const o = MOVE_DIRECTION[i.data.direction] || MOVE_DIRECTION.right,
      n = {};
    for (var r in o.from) {
      let a = o.from[r],
        m = o.to[r];
      "exit" === i.type && (a = o.to[r], m = -o.from[r]);
      const h = e[r] + a,
        d = e[r] + m - h;
      n[r] = h + t / i.duration * d
    }
    return n
  },
  zoom: ({
    dTime: t,
    element: e,
    animation: i
  }) => {
    const o = {},
      n = {
        x: e.x + e.width / 3,
        y: e.y + e.height / 3,
        width: e.width / 3,
        height: e.height / 3,
        fontSize: e.fontSize / 3
      },
      r = {
        x: e.x,
        y: e.y,
        width: e.width,
        height: e.height,
        fontSize: e.fontSize
      };
    for (var a in n) {
      let e = n[a],
        m = r[a];
      if ("exit" === i.type) {
        const t = e;
        e = m, m = t
      }
      const h = m - e;
      o[a] = e + t / i.duration * h
    }
    return o
  }
};
const animate = ({
  element: t,
  dTime: e,
  animation: i
}) => {
  const o = exports.animations[i.name];
  return o ? o({
    element: t,
    dTime: e,
    animation: i
  }) : (console.error("Can not find animation type: " + i.name), {})
};
exports.animate = animate;
const registerAnimation = (t, e) => {};
exports.registerAnimation = registerAnimation;
