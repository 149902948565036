"use strict";
async function getVideoSize(e) {
  const t = document.createElement("video");
  t.crossOrigin = "anonymous", t.src = e, await t.play(), t.pause();
  const {
    videoWidth: i,
    videoHeight: o
  } = t;
  return {
    width: i,
    height: o
  }
}
async function getVideoDuration(e) {
  const t = document.createElement("video");
  t.crossOrigin = "anonymous", t.src = e, await t.play(), t.pause();
  const {
    duration: i
  } = t;
  return i
}
async function getVideoPreview(e, t = 5) {
  return new Promise(((i, o) => {
    var r = document.createElement("video"),
      n = document.createElement("canvas");
    n.width = 480, n.height = 360;
    var d = n.getContext("2d");
    r.crossOrigin = "anonymous", r.src = e, r.addEventListener("error", (e => {
      o(e)
    })), r.addEventListener("loadeddata", (function() {
      const e = r.videoWidth / r.videoHeight;
      n.width = 480, n.height = 480 / e, r.currentTime = t
    })), r.addEventListener("seeked", (function() {
      d.drawImage(r, 0, 0, n.width, n.height);
      try {
        var e = n.toDataURL();
        i(e)
      } catch (e) {
        o(e)
      }
    }))
  }))
}
async function getVideoObjectPreview(e, t, i) {
  return new Promise(((o, r) => {
    const n = t.getContext("2d"),
      d = () => {
        try {
          n.drawImage(e, 0, 0, t.width, t.height);
          const i = t.toDataURL();
          o(i), e.removeEventListener("seeked", d)
        } catch (t) {
          r(t), e.removeEventListener("seeked", d)
        }
      };
    e.addEventListener("seeked", d), e.currentTime = i
  }))
}
Object.defineProperty(exports, "__esModule", {
  value: !0
}), exports.getVideoObjectPreview = exports.getVideoPreview = exports.getVideoDuration = exports.getVideoSize = void 0, exports.getVideoSize = getVideoSize, exports.getVideoDuration = getVideoDuration, exports.getVideoPreview = getVideoPreview, exports.getVideoObjectPreview = getVideoObjectPreview;
