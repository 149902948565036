"use strict";
Object.defineProperty(exports, "__esModule", {
  value: !0
}), exports.TextElement = void 0;
const mobx_state_tree_1 = require("mobx-state-tree"),
  shape_model_1 = require("./shape-model");
exports.TextElement = shape_model_1.Shape.named("Text").props({
  type: "text",
  text: "",
  placeholder: "",
  fontSize: 14,
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: "normal",
  textDecoration: "",
  fill: "black",
  align: "center",
  width: 100,
  height: 0,
  verticalAlign: "top",
  strokeWidth: 0,
  stroke: "black",
  lineHeight: mobx_state_tree_1.types.optional(mobx_state_tree_1.types.union(mobx_state_tree_1.types.number, mobx_state_tree_1.types.string), 1.2),
  letterSpacing: 0,
  _editModeEnabled: !1,
  backgroundEnabled: !1,
  backgroundColor: "#7ED321",
  backgroundOpacity: 1,
  backgroundCornerRadius: .5,
  backgroundPadding: .5
}).preProcessSnapshot((e => Object.assign({}, e))).actions((e => ({
  toggleEditMode(t) {
    e._editModeEnabled = null != t ? t : !e._editModeEnabled, e._editModeEnabled ? e.store.history.startTransaction() : e.store.history.endTransaction()
  }
})));
