"use strict";
Object.defineProperty(exports, "__esModule", {
  value: !0
}), exports.handleHotkey = void 0;
const group_model_1 = require("../model/group-model");
let _LOCAL_CLIPBOARD = [],
  copyPage = "";
const KEY = "polotno_clipboard",
  setClipBoard = e => {
    _LOCAL_CLIPBOARD = JSON.parse(JSON.stringify(e));
    try {
      localStorage.setItem(KEY, JSON.stringify(e))
    } catch (e) {}
  },
  getClipBoard = () => {
    try {
      const e = localStorage.getItem(KEY);
      if (e) return JSON.parse(e)
    } catch (e) {}
    return _LOCAL_CLIPBOARD
  };

function handleHotkey(e, t) {
  var o, l, d, a, r, i, n, c;
  if ("INPUT" === (null === (o = document.activeElement) || void 0 === o ? void 0 : o.tagName) || "TEXTAREA" === (null === (l = document.activeElement) || void 0 === l ? void 0 : l.tagName) || "true" === (null === (d = document.activeElement) || void 0 === d ? void 0 : d.contentEditable)) return;
  const s = t.selectedElements.filter((e => e.removable)),
    v = s.map((e => e.id));
  46 !== e.keyCode && 8 !== e.keyCode || t.deleteElements(v);
  const p = e.ctrlKey || e.metaKey,
    u = e.shiftKey;
  if (p && !u && "KeyZ" === e.code && (e.preventDefault(), t.history.undo()), p && u && "KeyZ" === e.code && (e.preventDefault(), t.history.redo()), p && "KeyA" === e.code) {
    e.preventDefault();
    const o = null === (a = t.activePage) || void 0 === a ? void 0 : a.children.filter((e => e.selectable)),
      l = (null == o ? void 0 : o.map((e => e.id))) || [];
    t.selectElements(l)
  }
  if (p && "KeyC" === e.code && (e.preventDefault(), copyPage = null === (r = t.activePage) || void 0 === r ? void 0 : r.id, setClipBoard(t.selectedElements.map((e => e.toJSON())))), p && "KeyX" === e.code && (e.preventDefault(), copyPage = null === (i = t.activePage) || void 0 === i ? void 0 : i.id, setClipBoard(t.selectedElements.map((e => e.toJSON()))), t.deleteElements(s)), p && "KeyV" === e.code) {
    e.preventDefault();
    let o = 0;
    copyPage === (null === (n = t.activePage) || void 0 === n ? void 0 : n.id) && (o = t.width / 20), copyPage = null === (c = t.activePage) || void 0 === c ? void 0 : c.id, t.history.transaction((() => {
      const e = [],
        l = getClipBoard();
      (0, group_model_1.forEveryChild)({
        children: l
      }, (e => {
        delete e.id, "group" !== e.type && (e.x += o, e.y += o)
      })), l.forEach((o => {
        var l;
        const d = null === (l = t.activePage) || void 0 === l ? void 0 : l.addElement(o);
        d && e.push(d.id)
      })), setClipBoard(l), t.selectElements(e)
    }))
  }
  "ArrowDown" === e.code && (e.preventDefault(), t.selectedElements.forEach((e => {
    e.set({
      y: e.y + 1
    })
  }))), "ArrowUp" === e.code && (e.preventDefault(), t.selectedElements.forEach((e => {
    e.set({
      y: e.y - 1
    })
  }))), "ArrowLeft" === e.code && (e.preventDefault(), t.selectedElements.forEach((e => {
    e.set({
      x: e.x - 1
    })
  }))), "ArrowRight" === e.code && (e.preventDefault(), t.selectedElements.forEach((e => {
    e.set({
      x: e.x + 1
    })
  })))
}
exports.handleHotkey = handleHotkey;
