"use strict";
Object.defineProperty(exports, "__esModule", {
  value: !0
}), exports.UndoManager = void 0;
const mobx_state_tree_1 = require("mobx-state-tree"),
  deep_equal_1 = require("../utils/deep-equal");
Object.assign(mobx_state_tree_1.types), exports.UndoManager = mobx_state_tree_1.types.model("UndoManager", {
  history: mobx_state_tree_1.types.array(mobx_state_tree_1.types.frozen()),
  undoIdx: -1,
  targetPath: ""
}).views((e => ({
  get canUndo() {
    return e.undoIdx > 0
  },
  get canRedo() {
    return e.undoIdx < e.history.length - 1
  }
}))).actions((e => {
  let t, o, a = !1,
    r = null,
    s = 0,
    n = 0;
  const d = () => n > 0;

  function i() {
    const e = (0, mobx_state_tree_1.getSnapshot)(t);
    return {
      pages: e.pages,
      width: e.width,
      height: e.height,
      custom: e.custom
    }
  }

  function u(e) {
    const o = t.pages.map((e => e.id)),
      a = e.pages.map((e => e.id)),
      r = !(0, deep_equal_1.deepEqual)(o, a),
      s = !(0, deep_equal_1.deepEqual)(t.custom, e.custom);
    r ? (0, mobx_state_tree_1.applySnapshot)(t.pages, e.pages) : t.pages.forEach(((t, o) => {
      (0, mobx_state_tree_1.applySnapshot)(t, e.pages[o])
    })), s && t.set({
      custom: e.custom
    }), t.setSize(e.width, e.height)
  }
  return {
    startTransaction() {
      n++
    },
    endTransaction(e) {
      n--, e || this.requestAddState(i())
    },
    async ignore(t, o = !1, a = !1) {
      a && await new Promise((e => setTimeout(e, 10))), s && e.addUndoState();
      let r = d();
      e.startTransaction();
      let i = n;
      try {
        await t()
      } catch (e) {
        setTimeout((() => {
          throw e
        }))
      }
      const u = i !== n,
        c = !u;
      e.endTransaction(c), o || r || e.replaceState(), u || (clearTimeout(s), s = 0)
    },
    async transaction(t) {
      await e.ignore(t, !0), this.addUndoState()
    },
    requestAddState(e) {
      r = e, s || d() || (a ? a = !1 : s = setTimeout((() => {
        s = 0, clearTimeout(s), d() || this.addUndoState()
      }), 100))
    },
    addUndoState() {
      if (a) return void(a = !1);
      const t = e.history[e.undoIdx];
      !(0, deep_equal_1.deepEqual)(r, t) && (clearTimeout(s), s = 0, e.history.splice(e.undoIdx + 1), e.history.push(r), e.undoIdx = e.history.length - 1)
    },
    afterCreate() {
      if (t = (0, mobx_state_tree_1.resolvePath)(e, ".."), !t) throw new Error("Failed to find target store for UndoManager. Please provide `targetPath` property, or a `targetStore` in the environment");
      o = (0, mobx_state_tree_1.onSnapshot)(t, (() => {
        this.requestAddState(i())
      })), 0 === e.history.length && this.requestAddState(i())
    },
    clear() {
      clearTimeout(s), s = 0, e.history.splice(0, e.history.length), e.undoIdx = -1, e.addUndoState(i())
    },
    beforeDestroy() {
      o()
    },
    undo() {
      s && this.addUndoState(), e.canUndo ? (e.undoIdx--, a = !0, u(e.history[e.undoIdx])) : console.warn("No undo history. Please check `store.history.canUndo` before calling undo action.")
    },
    redo() {
      s && this.addUndoState(), e.canRedo ? (e.undoIdx++, a = !0, u(e.history[e.undoIdx])) : console.warn("No redo history. Please check `store.history.canRedo` before calling redo action.")
    },
    replaceState() {
      e.history[e.undoIdx] = i()
    }
  }
})), exports.default = exports.UndoManager;
