"use strict";
Object.defineProperty(exports, "__esModule", {
  value: !0
}), exports.trySetCanvasSize = void 0;
const trySetCanvasSize = (t, e, a) => {
  let r = e,
    i = a,
    o = 1;
  for (; r > 0 && i > 0;) {
    t.width = r, t.height = i;
    const h = t.getContext("2d");
    if (!h) return;
    h.fillStyle = "rgba(0,0,0,0.1)", h.fillRect(0, 0, 1, 1);
    if (0 !== h.getImageData(0, 0, 1, 1).data[3]) return void h.clearRect(0, 0, t.width, t.height);
    if (o *= .9, r = Math.floor(e * o), i = Math.floor(a * o), r = Math.max(r, 1), i = Math.max(i, 1), r <= 1 && i <= 1) return t.width = 1, void(t.height = 1)
  }
};
exports.trySetCanvasSize = trySetCanvasSize;
