"use strict";
Object.defineProperty(exports, "__esModule", {
  value: !0
}), exports.selectImage = void 0;
const image_1 = require("../utils/image"),
  image_2 = require("../utils/image"),
  selectImage = async ({
    src: e,
    droppedPos: t,
    targetElement: i,
    store: s
  }) => {
    var a;
    if (i && "svg" === i.type && i.contentEditable) {
      const t = i.parent,
        a = i.zIndex,
        n = Object.assign({}, i.toJSON());
      delete n.id, s.deleteElements([i.id]);
      return void t.addElement(Object.assign(Object.assign({}, n), {
        type: "image",
        src: e,
        clipSrc: n.src
      })).setZIndex(a)
    }
    i && "figure" === i.type && i.contentEditable;
    let {
      width: n,
      height: g
    } = await (0, image_1.getImageSize)(e);
    if (i && "image" === i.type && i.contentEditable) {
      const t = (0, image_2.getCrop)(i, {
        width: n,
        height: g
      });
      return void i.set(Object.assign({
        src: e
      }, t))
    }
    const d = Math.min(s.width / n, s.height / g, 1);
    n *= d, g *= d;
    const c = ((null == t ? void 0 : t.x) || s.width / 2) - n / 2,
      r = ((null == t ? void 0 : t.y) || s.height / 2) - g / 2;
    null === (a = s.activePage) || void 0 === a || a.addElement({
      type: "image",
      src: e,
      width: n,
      height: g,
      x: c,
      y: r
    })
  };
exports.selectImage = selectImage;
