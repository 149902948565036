"use strict";
Object.defineProperty(exports, "__esModule", {
  value: !0
}), exports.selectVideo = void 0;
const selectVideo = async ({
  src: e,
  droppedPos: t,
  targetElement: i,
  store: o,
  attrs: d = {}
}) => {
  var s;
  let l = d.width || 300,
    r = d.height || 200;
  const c = .8 * Math.min(o.width / l, o.height / r, 1);
  l *= c, r *= c;
  const h = ((null == t ? void 0 : t.x) || o.width / 2) - l / 2,
    n = ((null == t ? void 0 : t.y) || o.height / 2) - r / 2;
  return null === (s = o.activePage) || void 0 === s ? void 0 : s.addElement({
    type: "video",
    src: e,
    width: l,
    height: r,
    x: h,
    y: n
  })
};
exports.selectVideo = selectVideo;
