"use strict";
var __createBinding = this && this.__createBinding || (Object.create ? function(e, o, t, i) {
    void 0 === i && (i = t);
    var n = Object.getOwnPropertyDescriptor(o, t);
    n && !("get" in n ? !o.__esModule : n.writable || n.configurable) || (n = {
      enumerable: !0,
      get: function() {
        return o[t]
      }
    }), Object.defineProperty(e, i, n)
  } : function(e, o, t, i) {
    void 0 === i && (i = t), e[i] = o[t]
  }),
  __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function(e, o) {
    Object.defineProperty(e, "default", {
      enumerable: !0,
      value: o
    })
  } : function(e, o) {
    e.default = o
  }),
  __importStar = this && this.__importStar || function(e) {
    if (e && e.__esModule) return e;
    var o = {};
    if (null != e)
      for (var t in e) "default" !== t && Object.prototype.hasOwnProperty.call(e, t) && __createBinding(o, e, t);
    return __setModuleDefault(o, e), o
  };
Object.defineProperty(exports, "__esModule", {
  value: !0
}), exports.validateKey = exports.isKeyPaid = exports.getKey = exports.shouldShowCredit = void 0;
const mobx = __importStar(require("mobx")),
  api_1 = require("./api"),
  flags_1 = require("./flags");
exports.shouldShowCredit = mobx.observable({
  value: !1
});
const showCredit = mobx.action((() => {
  exports.shouldShowCredit.value = !0
}));
let API_KEY = "";
const getKey = () => API_KEY || "";
exports.getKey = getKey;
const ATTEMPTS = 5,
  TIMEOUT = 3e3,
  NO_KEY_MESSAGE = "Polotno API is initialized without API key. It may lead to unexpected behavior and stop working. Please create API key here: https://polotno.com/cabinet",
  NO_VALID_MESSAGE = "Polotno API key is not valid. Please get new API key here: https://polotno.com/cabinet",
  ERROR_MESSAGE = "Can not validate Polotno API key. Please report to anton@polotno.com immediately.",
  NOT_PAID_MESSAGE = "%cPolotno Free Version. Development or non-commercial usage only. https://polotno.com/";
let origin = window.location.origin;
const headless = navigator.userAgent.indexOf("Headless") > -1,
  electron = navigator.userAgent.indexOf("Electron") > -1;
"file://" === origin && headless && (origin = "headless"), "file://" === origin && electron && (origin = "electron");
const BAD_DOMAIN_MESSAGE = `%cPolotno error! Current domain is not allowed. It may lead to unexpected behavior and stop working. Please add "${origin}" here: https://polotno.com/cabinet`;
async function isKeyPaid(e) {
  for (let o = 0; o < ATTEMPTS; o++) try {
    // const o = await fetch((0, api_1.getAPI)() + "/validate-key", {
    //   method: "POST",
    //   body: JSON.stringify({
    //     key: e,
    //     site: location.host
    //   })
    // });
    // if (!e) return console.warn(NO_KEY_MESSAGE), !1;
    // if (200 !== o.status) {
    //   await new Promise((e => setTimeout(e, TIMEOUT)));
    //   continue
    // }
    // const t = await o.json();
    const t = {
      is_valid: true,
      is_paid: true,
      is_domain_valid: true,
      remove_background_enabled: true
    };
    return t.is_valid || console.warn(NO_VALID_MESSAGE), t.is_paid || console.log(NOT_PAID_MESSAGE, "background: rgb(0, 161, 255); color: white; padding: 5px; margin: 5px;"), t.is_domain_valid || console.log(BAD_DOMAIN_MESSAGE, "background: rgba(247, 101, 68, 1); color: white; padding: 5px; margin: 5px;"), (0, flags_1.useRemoveBackground)(t.remove_background_enabled), t.is_paid || !1
  } catch (e) {
    await new Promise((e => setTimeout(e, TIMEOUT)))
  }
  return console.error(ERROR_MESSAGE), !0
}
async function validateKey(e, o) {
  API_KEY = e;
  await isKeyPaid(e) && !o || showCredit()
}
exports.isKeyPaid = isKeyPaid, exports.validateKey = validateKey;
