"use strict";
Object.defineProperty(exports, "__esModule", {
  value: !0
}), exports.Page = void 0;
const mobx_state_tree_1 = require("mobx-state-tree"),
  nanoid_1 = require("nanoid"),
  group_model_1 = require("./group-model"),
  store_1 = require("./store"),
  group_model_2 = require("./group-model"),
  math_1 = require("../utils/math");
exports.Page = mobx_state_tree_1.types.model("Page", {
  id: mobx_state_tree_1.types.identifier,
  children: mobx_state_tree_1.types.array(mobx_state_tree_1.types.late((() => group_model_1.ElementTypes))),
  width: mobx_state_tree_1.types.optional(mobx_state_tree_1.types.union(mobx_state_tree_1.types.number, mobx_state_tree_1.types.literal("auto")), "auto"),
  height: mobx_state_tree_1.types.optional(mobx_state_tree_1.types.union(mobx_state_tree_1.types.number, mobx_state_tree_1.types.literal("auto")), "auto"),
  background: "white",
  bleed: 0,
  custom: mobx_state_tree_1.types.frozen(),
  duration: 5e3,
  _exporting: !1,
  _rendering: !1
}).postProcessSnapshot((e => {
  const t = Object.assign({}, e),
    o = {};
  for (var n in t) "_" !== n[0] && (o[n] = e[n]);
  return o
})).views((e => ({
  get store() {
    return (0, mobx_state_tree_1.getParentOfType)(e, store_1.Store)
  }
}))).views((e => ({
  get startTime() {
    let t = 0;
    for (const o of e.store.pages) {
      if (o.id === e.id) return t;
      t += o.duration
    }
    return t
  },
  get _exportingOrRendering() {
    return e._exporting || e._rendering
  }
}))).views((e => ({
  get computedWidth() {
    return "auto" === e.width ? e.store.width : e.width
  },
  get computedHeight() {
    return "auto" === e.height ? e.store.height : e.height
  }
}))).actions((e => ({
  toJSON: () => JSON.parse(JSON.stringify((0, mobx_state_tree_1.getSnapshot)(e))),
  _forEachElementUp(t, o) {
    const n = t.map((t => ({
      id: t,
      index: e.children.findIndex((e => e.id === t))
    })));
    n.sort(((e, t) => t.index - e.index));
    for (const {
        index: i
      } of n) {
      if (-1 == i) continue;
      const n = i < e.children.length - 1 && e.children[i + 1],
        r = t.indexOf(null == n ? void 0 : n.id) >= 0;
      i === e.children.length - 1 || r || o(i)
    }
  },
  _forEachElementDown(t, o) {
    const n = t.map((t => ({
      id: t,
      index: e.children.findIndex((e => e.id === t))
    })));
    n.sort(((e, t) => e.index - t.index));
    for (const {
        index: i
      } of n) {
      if (-1 == i) continue;
      const n = i > 0 && e.children[i - 1],
        r = t.indexOf(null == n ? void 0 : n.id) >= 0;
      0 === i || r || o(i)
    }
    return !1
  }
}))).actions((e => ({
  clone(t = {}) {
    const o = e.toJSON();
    o.children.forEach((e => {
      e.id = (0, nanoid_1.nanoid)(10), (0, group_model_2.forEveryChild)(e, (e => {
        e.id = (0, nanoid_1.nanoid)(10)
      }))
    }));
    const n = Object.assign(Object.assign(Object.assign({}, o), {
        id: (0, nanoid_1.nanoid)(10)
      }), t),
      i = e.store.addPage(n),
      r = e.store.pages.indexOf(e);
    i.setZIndex(r + 1), i.select()
  },
  setZIndex(t) {
    e.store.setPageZIndex(e.id, t)
  },
  set(t) {
    Object.assign(e, t)
  },
  select() {
    e.store.selectPage(e.id)
  },
  addElement(t, {
    skipSelect: o = !1
  } = {}) {
    const n = group_model_2.TYPES_MAP[t.type];
    if (!n) return void console.error("Can not find model with type " + t.type);
    t.children && t.children.forEach((e => {
      e.id = (0, nanoid_1.nanoid)(10)
    }));
    const i = n.create(Object.assign({
      id: (0, nanoid_1.nanoid)(10)
    }, t));
    return e.children.push(i), i.selectable && !o && e.store.selectElements([i.id]), i
  },
  canMoveElementsUp(t) {
    let o = !1;
    return e._forEachElementUp(t, (() => {
      o = o || !0
    })), o
  },
  moveElementsUp(t) {
    e._forEachElementUp(t, (t => {
      const o = e.children[t];
      (0, mobx_state_tree_1.detach)(o), e.children.splice(t + 1, 0, o)
    }))
  },
  canMoveElementsTop(e) {
    return this.canMoveElementsUp(e)
  },
  moveElementsTop(t) {
    const o = [],
      n = [];
    e.children.forEach((e => {
      t.indexOf(e.id) >= 0 ? o.push(e) : n.push(e)
    })), e.children.replace(n.concat(o))
  },
  canMoveElementsDown(t) {
    let o = !1;
    return e._forEachElementDown(t, (() => {
      o = o || !0
    })), o
  },
  moveElementsDown(t) {
    e._forEachElementDown(t, (t => {
      const o = e.children[t];
      (0, mobx_state_tree_1.detach)(o), e.children.splice(t - 1, 0, o)
    }))
  },
  canMoveElementsBottom(e) {
    return this.canMoveElementsDown(e)
  },
  moveElementsBottom(t) {
    const o = [],
      n = [];
    e.children.forEach((e => {
      t.indexOf(e.id) >= 0 ? o.push(e) : n.push(e)
    })), e.children.replace(o.concat(n))
  },
  setElementZIndex(t, o) {
    const n = e.children.find((e => e.id === t));
    n && ((0, mobx_state_tree_1.detach)(n), e.children.remove(n), e.children.splice(o, 0, n))
  },
  setSize({
    width: t,
    height: o,
    useMagic: n,
    softChange: i
  }) {
    if (n) {
      const n = [],
        i = t => "image" === t.type && t.x < 1 && t.y < 1 && t.width >= e.computedWidth - 2 && t.height >= e.computedHeight - 2;
      (0, group_model_2.forEveryChild)(e, (e => {
        "group" !== e.type && (i(e) || n.push(e))
      }));
      const r = n.length ? (0, math_1.getTotalClientRect)(n) : {
          x: 0,
          y: 0,
          width: e.computedWidth,
          height: e.computedHeight
        },
        s = 1 / 4,
        d = Math.max(0, Math.min(r.x, e.computedWidth - r.x - r.width)),
        a = e.computedWidth * s,
        m = Math.max(0, d - a),
        c = Math.max(0, Math.min(r.y, e.computedHeight - r.y - r.height)),
        h = e.computedHeight * s,
        l = Math.max(0, c - h),
        p = e.computedWidth - 2 * m,
        _ = e.computedHeight - 2 * l,
        u = t / p,
        g = o / _,
        x = Math.min(u, g),
        E = (t - p * x) / 2 - m * x,
        f = (o - _ * x) / 2 - l * x;
      (0, group_model_2.forEveryChild)(e, (e => {
        if ("group" === e.type) return;
        i(e) ? e.set({
          x: e.x * x,
          y: e.y * x,
          width: e.width * u,
          height: e.height * g,
          cropX: 0,
          cropY: 0,
          cropWidth: 1,
          cropHeight: 1
        }) : (e.set({
          x: E + e.x * x,
          y: f + e.y * x,
          width: e.width * x,
          height: e.height * x
        }), "text" === e.type ? e.set({
          fontSize: e.fontSize * x
        }) : "figure" === e.type && e.set({
          strokeWidth: e.strokeWidth * x
        }))
      }))
    }
    i || (e.width = t), i || (e.height = o)
  }
}))).actions((e => ({
  moveElementUp(t) {
    console.warn("page.moveElementUp(id) is deprecated. Please use page.moveElementsUp([id1, id2]) instead."), e.moveElementsUp([t])
  },
  moveElementDown(t) {
    console.warn("page.moveElementDown(id) is deprecated. Please use page.moveElementsDown([id1, id2]) instead."), e.moveElementsDown([t])
  },
  moveElementTop(t) {
    console.warn("page.moveElementTop(id) is deprecated. Please use page.moveElementsTop([id1, id2]) instead."), e.moveElementsTop([t])
  },
  moveElementBottom(t) {
    console.warn("page.moveElementBottom(id) is deprecated. Please use page.moveElementsBottom([id1, id2]) instead."), e.moveElementsBottom([t])
  },
  play() {
    e.store.play({
      startTime: e.startTime,
      endTime: e.startTime + e.duration
    })
  }
})));
