"use strict";
Object.defineProperty(exports, "__esModule", {
  value: !0
}), exports.Audio = void 0;
const mobx_state_tree_1 = require("mobx-state-tree");
exports.Audio = mobx_state_tree_1.types.model("Audio", {
  id: mobx_state_tree_1.types.identifier,
  src: "",
  duration: 0,
  startTime: 0,
  endTime: 1,
  volume: 1,
  delay: 0
}).actions((e => ({
  toJSON: () => Object.assign({}, (0, mobx_state_tree_1.getSnapshot)(e))
}))).actions((e => ({
  set(t) {
    Object.assign(e, t)
  }
})));
