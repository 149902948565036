"use strict";
var __createBinding = this && this.__createBinding || (Object.create ? function(e, t, o, i) {
    void 0 === i && (i = o);
    var n = Object.getOwnPropertyDescriptor(t, o);
    n && !("get" in n ? !t.__esModule : n.writable || n.configurable) || (n = {
      enumerable: !0,
      get: function() {
        return t[o]
      }
    }), Object.defineProperty(e, i, n)
  } : function(e, t, o, i) {
    void 0 === i && (i = o), e[i] = t[o]
  }),
  __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function(e, t) {
    Object.defineProperty(e, "default", {
      enumerable: !0,
      value: t
    })
  } : function(e, t) {
    e.default = t
  }),
  __importStar = this && this.__importStar || function(e) {
    if (e && e.__esModule) return e;
    var t = {};
    if (null != e)
      for (var o in e) "default" !== o && Object.prototype.hasOwnProperty.call(e, o) && __createBinding(t, e, o);
    return __setModuleDefault(t, e), t
  },
  __rest = this && this.__rest || function(e, t) {
    var o = {};
    for (var i in e) Object.prototype.hasOwnProperty.call(e, i) && t.indexOf(i) < 0 && (o[i] = e[i]);
    if (null != e && "function" == typeof Object.getOwnPropertySymbols) {
      var n = 0;
      for (i = Object.getOwnPropertySymbols(e); n < i.length; n++) t.indexOf(i[n]) < 0 && Object.prototype.propertyIsEnumerable.call(e, i[n]) && (o[i[n]] = e[i[n]])
    }
    return o
  };
Object.defineProperty(exports, "__esModule", {
  value: !0
}), exports.jsonToHTML = exports.jsonToDOM = exports.fixRatio = void 0;
const image_1 = require("./image"),
  svg = __importStar(require("./svg")),
  image_2 = require("./image"),
  figure_to_svg_1 = require("./figure-to-svg"),
  html2canvas_1 = require("./html2canvas"),
  h = (e, t, ...o) => ({
    type: e,
    props: t,
    children: o || []
  });

function fixRatio(e) {
  var t = (new DOMParser).parseFromString(e, "image/svg+xml");
  t.documentElement.setAttribute("preserveAspectRatio", "none");
  return (new XMLSerializer).serializeToString(t)
}
exports.fixRatio = fixRatio;
const imageToDOM = async ({
  element: e,
  page: t,
  store: o
}) => {
  let {
    src: i
  } = e;
  if ("svg" === e.type && Object.keys(e.colorsReplace).length) {
    const t = await svg.urlToString(i);
    i = svg.replaceColors(t, new Map(Object.entries(e.colorsReplace)))
  }
  let n = "";
  e.flipX && (n += "scaleX(-1)"), e.flipY && (n += "scaleY(-1)"), n || (n = "none");
  const r = {};
  if (e.clipSrc) {
    const t = await svg.urlToBase64(e.clipSrc);
    r["clip-path"] = `url(${t})`
  }
  const s = await (0, image_2.loadImage)(i),
    a = s.width * e.cropWidth,
    l = s.height * e.cropHeight,
    g = e.width / e.height;
  let c, p;
  const d = a / l;
  "svg" === e.type ? (c = a, p = l) : g >= d ? (c = a, p = a / g) : (c = l * g, p = l);
  const u = c / s.width,
    f = p / s.height,
    m = c / p > e.width / e.height ? e.height / p : e.width / c,
    b = c * m / u,
    y = p * m / f,
    O = e.cropX * m * s.width,
    x = e.cropY * m * s.height;
  return h("div", {
    style: Object.assign(Object.assign({}, r), {
      width: "100%",
      height: "100%",
      "border-radius": e.cornerRadius + "px",
      border: e.borderSize ? `${e.borderSize}px solid ${e.borderColor}` : "none",
      background: `url(${i})`,
      transform: n,
      "background-size": `${Math.round(b)}px ${Math.round(y)}px`,
      "background-position-x": -Math.round(O) + "px",
      "background-position-y": -Math.round(x) + "px"
    })
  })
}, textToDOM = async ({
  element: e,
  page: t,
  store: o
}) => {
  let i = {
    top: 0,
    left: 0
  };
  e.fill.indexOf("gradient") >= 0 && (i = Object.assign(Object.assign({}, i), {
    "background-color": e.fill,
    "background-image": e.fill,
    "background-clip": "text",
    "text-fill-color": "transparent",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent"
  }));
  const n = e.backgroundPadding * (e.fontSize * e.lineHeight),
    r = h("div", {
      style: {
        position: "absolute",
        top: -n / 2 + "px",
        left: -n / 2 + "px",
        display: e.backgroundEnabled ? "block" : "none",
        width: e.width + n + "px",
        height: e.height + n + "px",
        "background-color": e.backgroundColor,
        "border-radius": e.backgroundCornerRadius * (e.fontSize * e.lineHeight * .5) + "px"
      }
    });
  "middle" === e.verticalAlign ? (i.top = "50%", i.transform = "translateY(-50%)") : "bottom" === e.verticalAlign && (i.bottom = 0);
  const s = /<[a-z][\s\S]*>/i.test(e.text),
    a = Object.assign(Object.assign({}, i), {
      position: "absolute",
      width: e.width + "px",
      color: e.fill,
      "white-space": "pre-wrap",
      "font-size": e.fontSize + "px",
      "text-align": e.align,
      "font-family": e.fontFamily,
      "text-decoration": e.textDecoration || "none",
      "line-height": e.lineHeight,
      "letter-spacing": e.letterSpacing + "em",
      "font-style": e.fontStyle,
      "font-weight": e.fontWeight,
      "-webkit-text-stroke": `${e.strokeWidth}px ${e.stroke}`,
      "text-stroke": `${e.strokeWidth}px ${e.stroke}`
    }),
    l = "el-" + e.id,
    g = s ? {
      id: l
    } : {},
    c = `<style>#${l} {${html2canvas_1.resetStyleContent}}</style>`,
    p = h("div", Object.assign(Object.assign({
      style: a
    }, g), {
      innerHTML: s ? `${c}${e.text}` : e.text.split("\n").join("<br />")
    }));
  return h("div", {
    style: {
      position: "relative",
      width: "100%",
      height: "100%"
    }
  }, r, p)
}, getLineHead = ({
  element: e,
  type: t
}) => {
  const o = {
    "stroke-width": e.height,
    stroke: e.color,
    "line-cap": "round",
    "stroke-linejoin": "round",
    opacity: e.opacity
  };
  return "arrow" === t || "triangle" === t ? h("polyline", Object.assign({
    points: `${3*e.height},${2*-e.height} 0,0 ${3*e.height},${2*e.height}`
  }, o)) : "bar" === t ? h("polyline", Object.assign({
    points: `0,${2*-e.height} 0,${2*e.height}`
  }, o)) : "circle" === t ? h("circle", Object.assign({
    r: e.height
  }, o)) : "square" === t ? h("polyline", Object.assign({
    points: `${-e.height},${-e.height} ${-e.height},${e.height} ${e.height},${e.height} ${e.height},${-e.height}`
  }, o)) : null
}, lineToDOM = async ({
  element: e,
  page: t,
  store: o
}) => h("svg", {
  style: {
    width: "100%",
    height: "100%",
    contain: "layout style size",
    overflow: "visible"
  }
}, h("rect", {
  x: 0,
  y: 0,
  width: e.width,
  height: e.height,
  fill: e.color
}), h("g", {
  transform: `translate(0 ${e.height/2})`
}, getLineHead({
  element: e,
  type: e.startHead
})), h("g", {
  transform: `translate(${e.width} ${e.height/2}) rotate(180)`
}, getLineHead({
  element: e,
  type: e.endHead
}))), groupToDOM = async ({
  element: e,
  page: t,
  store: o,
  elementHook: i
}) => {
  const n = await Promise.all(e.children.map((e => elementToDOM({
      element: e,
      page: t,
      store: o,
      elementHook: i
    })))),
    r = h("div", {
      style: {
        "transform-origin": "top left",
        opacity: e.opacity
      }
    }, ...n);
  return i && i({
    dom: r,
    element: e
  }) || r
}, figureToDOM = async ({
  element: e,
  page: t,
  store: o,
  elementHook: i
}) => {
  const n = (0, figure_to_svg_1.figureToSvg)(e),
    r = h("div", {
      innerHTML: n
    });
  return i && i({
    dom: r,
    element: e
  }) || r
}, type = {
  image: imageToDOM,
  svg: imageToDOM,
  text: textToDOM,
  line: lineToDOM,
  figure: figureToDOM,
  group: groupToDOM
};
async function elementToDOM({
  element: e,
  page: t,
  store: o,
  elementHook: i
}) {
  let n = await type[e.type];
  n || (n = () => h("div", {}), console.error(`HTML export does not support ${e.type} type...`));
  const r = await n({
      element: e,
      page: t,
      store: o
    }),
    s = [];
  e.blurEnabled && s.push(`blur(${e.blurRadius/2}px)`), e.brightnessEnabled && s.push(`brightness(${100*e.brightness+100}%)`), e.sepiaEnabled && s.push("sepia()"), e.grayscaleEnabled && s.push("grayscale()"), e.shadowEnabled && s.push(`drop-shadow(${e.shadowOffsetX}px ${e.shadowOffsetY}px ${e.shadowBlur}px ${e.shadowColor})`);
  const a = h("div", {
    id: e.id,
    style: {
      position: "absolute",
      left: e.x + "px",
      top: e.y + "px",
      width: e.width + "px",
      height: e.height + "px",
      transform: `rotate(${e.rotation}deg)`,
      "transform-origin": "top left",
      opacity: e.opacity,
      display: e.visible && e.showInExport ? "block" : "none",
      filter: s.join(" ") || "none"
    }
  }, r);
  return i && i({
    dom: a,
    element: e
  }) || a
}
async function pageToDOM({
  page: e,
  store: t,
  elementHook: o
}) {
  const i = await Promise.all(e.children.map((i => elementToDOM({
      element: i,
      page: e,
      store: t,
      elementHook: o
    })))),
    n = "auto" === e.width ? t.width : e.width,
    r = "auto" === e.height ? t.height : e.height;
  let s = {};
  if (e.background.indexOf("url") >= 0 || e.background.indexOf("http") >= 0 || e.background.indexOf(".jpg") >= 0 || e.background.indexOf(".png") >= 0 || e.background.indexOf(".jpeg") >= 0) {
    const {
      width: o,
      height: n
    } = await (0, image_2.loadImage)(e.background), r = await imageToDOM({
      element: Object.assign({
        x: 0,
        y: 0,
        width: o,
        height: n,
        src: e.background,
        cornerRadius: 0
      }, (0, image_1.getCrop)({
        width: o,
        height: n
      }, {
        width: o,
        height: n
      })),
      page: e,
      store: t
    });
    i.unshift(r)
  } else s = Object.assign(Object.assign({}, s), {
    "background-color": e.background
  });
  return h("div", {
    className: "page",
    id: e.id,
    style: Object.assign(Object.assign({}, s), {
      width: n + "px",
      height: r + "px",
      border: "1px solid grey",
      overflow: "hidden",
      position: "relative"
    })
  }, ...i)
}
async function jsonToDOM({
  json: e,
  elementHook: t
}) {
  const o = await Promise.all(e.pages.map((o => pageToDOM({
      page: o,
      store: e,
      elementHook: t
    })))),
    i = [];
  e.pages.forEach((e => {
    e.children.forEach((e => {
      "text" === e.type && -1 === i.indexOf(e.fontFamily) && i.push(e.fontFamily)
    }))
  }));
  const n = i.map((t => e.fonts.find((e => e.fontFamily === t)) ? h("style", {}, "") : h("link", {
    href: `https://fonts.googleapis.com/css?family=${t}`,
    rel: "stylesheet"
  })));
  return h("div", {
    className: "design"
  }, ...n, ...o)
}
exports.jsonToDOM = jsonToDOM;
const serializeProp = (e, t) => "object" == typeof t ? `${e}="${Object.keys(t).map((e=>`${e}:${t[e]};`)).join(" ")}"` : `${e}="${t}"`,
  domToHTML = ({
    dom: e
  }) => {
    if ("string" == typeof e) return e;
    if (!e) return "";
    const t = e.props,
      {
        innerHTML: o
      } = t,
      i = __rest(t, ["innerHTML"]);
    return `<${e.type} ${Object.keys(i).map((t=>serializeProp(t,e.props[t]))).join(" ")}>${o||e.children.map((e=>domToHTML({dom:e}))).join("")}</${e.type}>`
  };
async function jsonToHTML({
  json: e,
  elementHook: t
}) {
  const o = await jsonToDOM({
    json: e,
    elementHook: t
  });
  return domToHTML({
    dom: o
  })
}
exports.jsonToHTML = jsonToHTML;
