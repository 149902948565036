"use strict";
Object.defineProperty(exports, "__esModule", {
  value: !0
}), exports.triggerLoadError = exports.onLoadError = exports.whenLoaded = exports.decrementLoader = exports.incrementLoader = exports.getFontLoadTimeout = exports.setFontLoadTimeout = exports.getAssetLoadTimeout = exports.setAssetLoadTimeout = void 0;
const use_fadein_1 = require("../canvas/use-fadein");
let loadCount = 0,
  listeners = [],
  assetLoadTimeout = 3e4;
const setAssetLoadTimeout = e => {
  assetLoadTimeout = e
};
exports.setAssetLoadTimeout = setAssetLoadTimeout;
const getAssetLoadTimeout = () => assetLoadTimeout;
exports.getAssetLoadTimeout = getAssetLoadTimeout;
let fontLoadTimeout = 6e3;
const setFontLoadTimeout = e => {
  fontLoadTimeout = e
};
exports.setFontLoadTimeout = setFontLoadTimeout;
const getFontLoadTimeout = () => fontLoadTimeout;

function incrementLoader(e) {
  loadCount += 1;
  let o = !1;
  const t = setTimeout((() => {
    o || (triggerLoadError(`Timeout loading asset ${e}`), console.error("Timeout triggered for loader. Some assets may not have loaded.", e), o = !0, decrementLoader())
  }), assetLoadTimeout);
  return () => {
    o || (clearTimeout(t), decrementLoader())
  }
}

function decrementLoader() {
  loadCount -= 1, 0 === loadCount && (listeners.forEach((e => e())), listeners = [])
}

function whenLoadedCallback(e) {
  0 === loadCount ? e() : listeners.push(e)
}

function whenLoaded() {
  return new Promise((e => whenLoadedCallback((() => {
    (0, use_fadein_1.isAnimationUsed)() ? setTimeout(e, 300): e(!0)
  }))))
}
exports.getFontLoadTimeout = getFontLoadTimeout, exports.incrementLoader = incrementLoader, exports.decrementLoader = decrementLoader, exports.whenLoaded = whenLoaded;
let errorListeners = [];

function onLoadError(e) {
  errorListeners.push(e)
}

function triggerLoadError(e) {
  errorListeners.forEach((o => o(e)))
}
exports.onLoadError = onLoadError, exports.triggerLoadError = triggerLoadError;
