"use strict";

function getLimitedFontSize({
  oldText: t,
  newText: e,
  element: i
}) {
  if (!(e.length / Math.max(20, t.length) >= 4)) return i.fontSize;
  const n = i.width * i.page.computedHeight / e.length,
    o = 1.5 * Math.sqrt(n),
    r = i.fontSize;
  return Math.min(r, Math.max(5, Math.round(o)))
}
Object.defineProperty(exports, "__esModule", {
  value: !0
}), exports.getLimitedFontSize = void 0, exports.getLimitedFontSize = getLimitedFontSize;
