"use strict";
Object.defineProperty(exports, "__esModule", {
  value: !0
}), exports.VideoElement = void 0;
const image_model_1 = require("./image-model");
exports.VideoElement = image_model_1.ImageElement.named("Video").props({
  type: "video",
  duration: 0,
  startTime: 0,
  endTime: 1,
  volume: 1
});
