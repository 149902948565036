"use strict";
var __createBinding = this && this.__createBinding || (Object.create ? function(o, t, e, n) {
    void 0 === n && (n = e);
    var s = Object.getOwnPropertyDescriptor(t, e);
    s && !("get" in s ? !t.__esModule : s.writable || s.configurable) || (s = {
      enumerable: !0,
      get: function() {
        return t[e]
      }
    }), Object.defineProperty(o, n, s)
  } : function(o, t, e, n) {
    void 0 === n && (n = e), o[n] = t[e]
  }),
  __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function(o, t) {
    Object.defineProperty(o, "default", {
      enumerable: !0,
      value: t
    })
  } : function(o, t) {
    o.default = t
  }),
  __importStar = this && this.__importStar || function(o) {
    if (o && o.__esModule) return o;
    var t = {};
    if (null != o)
      for (var e in o) "default" !== e && Object.prototype.hasOwnProperty.call(o, e) && __createBinding(t, o, e);
    return __setModuleDefault(t, o), t
  };
Object.defineProperty(exports, "__esModule", {
  value: !0
}), exports.injectCustomFont = exports.injectGoogleFont = exports.getGoogleFontsVariants = exports.setGoogleFontsVariants = exports.loadFont = exports.isFontLoaded = exports.replaceGlobalFonts = exports.removeGlobalFont = exports.addGlobalFont = exports.globalFonts = exports.getFontsList = exports.setGoogleFonts = exports.isGoogleFontChanged = void 0;
const mobx = __importStar(require("mobx")),
  loader_1 = require("./loader");
let GOOGLE_FONTS = mobx.observable(["Roboto", "Amatic SC", "Press Start 2P", "Marck Script", "Rubik Mono One"]),
  googleFontsChanged = mobx.observable({
    value: !1
  });

function isGoogleFontChanged() {
  return googleFontsChanged.value
}

function setGoogleFonts(o) {
  "default" !== o ? (googleFontsChanged.value = !0, GOOGLE_FONTS.splice(0, GOOGLE_FONTS.length), GOOGLE_FONTS.push(...o)) : googleFontsChanged.value = !1
}

function getFontsList() {
  return GOOGLE_FONTS
}

function addGlobalFont(o) {
  exports.globalFonts.push(o)
}

function removeGlobalFont(o) {
  const t = exports.globalFonts.findIndex((t => t.fontFamily === o)); - 1 !== t && exports.globalFonts.splice(t, 1)
}

function replaceGlobalFonts(o) {
  exports.globalFonts.replace(o)
}
exports.isGoogleFontChanged = isGoogleFontChanged, exports.setGoogleFonts = setGoogleFonts, exports.getFontsList = getFontsList, exports.globalFonts = mobx.observable([]), exports.addGlobalFont = addGlobalFont, exports.removeGlobalFont = removeGlobalFont, exports.replaceGlobalFonts = replaceGlobalFonts;
var TEXT_TEXT = "Some test text;?#D-ПРИВЕТ!1230o9u8i7y6t5r4e3w2q1",
  canvas = document.createElement("canvas");

function measureArial() {
  return measureFont("Arial")
}

function measureTimes() {
  return measureFont("Times", "Times")
}

function measureFont(o, t = "Arial", e = "normal", n = "normal") {
  var s = canvas.getContext("2d");
  return s.font = `${e} ${n} 40px '${o}', ${t}`, s.measureText(TEXT_TEXT).width
}
const loadedFonts = {
    Arial: !0
  },
  isFontLoaded = o => !!loadedFonts[o];
async function loadFont(o, t, e) {
  var n;
  if (loadedFonts[o]) return;
  const s = !!(null === (n = document.fonts) || void 0 === n ? void 0 : n.load),
    r = measureFont("Arial", "Arial", t, e);
  if (s) try {
    await document.fonts.load(`16px '${o}', ${t} ${e}`);
    const n = measureFont(o, "Arial", t, e);
    if (r !== n) return void(loadedFonts[o] = !0)
  } catch (o) {}
  const a = measureFont("Times", "Times", t, e),
    l = measureFont(o, "Arial", t, e),
    i = Math.min(6e3, (0, loader_1.getFontLoadTimeout)()) / 60;
  for (let n = 0; n < i; n++) {
    const n = measureFont(o, "Arial", t, e),
      s = measureFont(o, "Times", t, e);
    if (n !== l || n !== r || s !== a) return void(loadedFonts[o] = !0);
    await new Promise((o => setTimeout(o, 60)))
  }
  console.warn(`Timeout for loading font "${o}". Looks like polotno can't load it. Is it a correct font family?`), (0, loader_1.triggerLoadError)(`Timeout for loading font "${o}"`)
}
exports.isFontLoaded = isFontLoaded, exports.loadFont = loadFont;
const registeredGoogleFonts = {};
let googleFontsVariants = "400,400italic,700,700italic";

function setGoogleFontsVariants(o) {
  googleFontsVariants = o
}

function getGoogleFontsVariants() {
  return googleFontsVariants
}

function injectGoogleFont(o) {
  if (registeredGoogleFonts[o]) return;
  const t = `https://fonts.googleapis.com/css?family=${o.replace(/ /g,"+")}:${googleFontsVariants}`,
    e = document.createElement("link");
  e.type = "text/css", e.href = t, e.rel = "stylesheet", document.getElementsByTagName("head")[0].appendChild(e), registeredGoogleFonts[o] = !0
}
exports.setGoogleFontsVariants = setGoogleFontsVariants, exports.getGoogleFontsVariants = getGoogleFontsVariants, exports.injectGoogleFont = injectGoogleFont;
const registeredCustomFonts = {};

function injectCustomFont(o) {
  const t = o.fontFamily;
  if (registeredCustomFonts[t]) return;
  if (!o.url && !o.styles) return;
  const e = document.createElement("style");
  e.type = "text/css";
  const n = o.styles || (o.url ? [{
    src: `url("${o.url}")`
  }] : []);
  let s = "";
  n.forEach((o => {
    s += `\n      @font-face {\n        font-family: '${t}';\n        src: ${o.src};\n        font-style: ${o.fontStyle||"normal"};\n        font-weight: ${o.fontWeight||"normal"};\n      }\n    `
  })), e.innerHTML = s, document.getElementsByTagName("head")[0].appendChild(e), registeredCustomFonts[o.fontFamily] = !0
}
exports.injectCustomFont = injectCustomFont;
