"use strict";
Object.defineProperty(exports, "__esModule", {
  value: !0
}), exports.t = exports.getTranslations = exports.setTranslations = void 0;
const mobx_1 = require("mobx"),
  translation = (0, mobx_1.observable)({
    toolbar: {
      duration: "Duration",
      opacity: "Opacity",
      effects: "Effects",
      blur: "Blur",
      textBackground: "Background",
      backgroundCornerRadius: "Corner radius",
      backgroundOpacity: "Opacity",
      backgroundPadding: "Padding",
      brightness: "Brightness",
      sepia: "Sepia",
      grayscale: "Grayscale",
      textStroke: "Text Stroke",
      shadow: "Shadow",
      border: "Border",
      cornerRadius: "Corner Radius",
      position: "Position",
      layering: "Layering",
      toForward: "To forward",
      up: "Up",
      down: "Down",
      toBottom: "To bottom",
      alignLeft: "Align left",
      alignCenter: "Align center",
      alignRight: "Align right",
      alignTop: "Align top",
      alignMiddle: "Align middle",
      alignBottom: "Align bottom",
      flip: "Flip",
      flipHorizontally: "Flip horizontally",
      flipVertically: "Flip vertically",
      fitToBackground: "Fit to page",
      removeBackground: "Remove background",
      removeBackgroundTitle: "Remove background from image",
      cancelRemoveBackground: "Cancel",
      confirmRemoveBackground: "Confirm",
      crop: "Crop",
      cropDone: "Done",
      cropCancel: "Cancel",
      clip: "Apply mask",
      removeClip: "Remove mask",
      removeMask: "Remove mask",
      transparency: "Transparency",
      lockedDescription: "Object is locked. Unlock it to allow changes from canvas.",
      unlockedDescription: "Object is unlocked. Lock it to prevent changes from canvas.",
      removeElements: "Remove elements",
      duplicateElements: "Duplicate elements",
      download: "Download",
      saveAsImage: "Save as image",
      saveAsPDF: "Save as PDF",
      lineHeight: "Line height",
      letterSpacing: "Letter spacing",
      offsetX: "Offset X",
      offsetY: "Offset Y",
      color: "Color",
      selectable: "Selectable",
      draggable: "Draggable",
      removable: "Removable",
      resizable: "Resizable",
      contentEditable: "Can change content",
      styleEditable: "Can change style",
      alwaysOnTop: "Always on top",
      showInExport: "Show in export",
      ungroupElements: "Ungroup",
      groupElements: "Group",
      lineSize: "Line size",
      fade: "Fade",
      move: "Move",
      zoom: "Zoom",
      animate: "Animate",
      rotate: "Rotate",
      none: "None",
      bounce: "Bounce",
      blink: "Blink",
      spaceEvenly: "Space evenly",
      horizontalDistribution: "Horizontally",
      verticalDistribution: "Vertically",
      strokeWidth: "Stroke Width",
      colorPicker: {
        solid: "Solid",
        linear: "Linear"
      },
      aiText: {
        rewrite: "Rewrite",
        shorten: "Shorten",
        continue: "Continue writing",
        proofread: "Proofread",
        tones: "Tones",
        friendly: "Friendly",
        professional: "Professional",
        humorous: "Humorous",
        formal: "Formal"
      }
    },
    workspace: {
      noPages: "There are no pages yet...",
      addPage: "Add page",
      removePage: "Remove page",
      duplicatePage: "Duplicate page",
      moveUp: "Move up",
      moveDown: "Move down"
    },
    scale: {
      reset: "Reset"
    },
    error: {
      removeBackground: "Ops! Something went wrong. Background can not be removed."
    },
    sidePanel: {
      templates: "Templates",
      searchTemplatesWithSameSize: "Show templates with the same size",
      searchPlaceholder: "Search...",
      otherFormats: "Other formats",
      noResults: "No results",
      error: "Loading is failed...",
      text: "Text",
      uploadFont: "Upload font",
      myFonts: "My fonts",
      photos: "Photos",
      videos: "Videos",
      animations: "Animations",
      effects: "Effects",
      elements: "Elements",
      shapes: "Shapes",
      lines: "Lines",
      upload: "Upload",
      uploadImage: "Upload Image",
      uploadTip: "Do you want to upload your own images?",
      background: "Background",
      resize: "Resize",
      layers: "Layers",
      layerTypes: {
        image: "Image",
        text: "Text",
        svg: "SVG",
        line: "Line",
        figure: "Figure",
        group: "Group"
      },
      layersTip: "Elements on your active page:",
      noLayers: "No elements on the page...",
      namePlaceholder: "Type element name...",
      useMagicResize: "Use magic resize",
      clipImage: "Mask image",
      width: "Width",
      height: "Height",
      magicResizeDescription: "Magic resize will automatically resize and move all elements on the canvas",
      headerText: "Header",
      createHeader: "Create header",
      subHeaderText: "Sub Header",
      createSubHeader: "Create sub header",
      bodyText: "Body text",
      createBody: "Create body text"
    },
    pagesTimeline: {
      pages: "Pages",
      removePage: "Remove page",
      addPage: "Add page",
      duplicatePage: "Duplicate page",
      removeAudio: "Remove audio"
    }
  });

function get(e, o) {
  var a, t = o.split("."),
    n = e;
  for (a = 0; a < t.length; ++a) {
    if (null == n[t[a]]) return;
    n = n[t[a]]
  }
  return n
}
const isObject = e => e && "object" == typeof e;

function mergeDeep(e, o) {
  Object.keys(o).forEach((a => {
    const t = e[a],
      n = o[a];
    isObject(n) && isObject(t) ? mergeDeep(t, n) : e[a] = n
  }))
}

function validateDeep(e, o, a = "") {
  Object.keys(o).forEach((t => {
    const n = o[t],
      r = a ? `${a}.${t}` : t;
    isObject(n) ? isObject(e[t]) ? validateDeep(e[t], n, r) : console.warn(`Missing nested translation object at '${r}'`) : void 0 === e[t] && console.warn(`Missing translation '${r}'`)
  }))
}
exports.setTranslations = (0, mobx_1.action)(((e, {
  validate: o = !1
} = {}) => {
  o && validateDeep(e, translation), mergeDeep(translation, e)
}));
const getTranslations = () => (0, mobx_1.toJS)(translation);
exports.getTranslations = getTranslations;
const WARNED = {},
  t = e => {
    const o = get(translation, e);
    if (void 0 !== o) return o;
    WARNED[e] || (WARNED[e] = !0, console.warn(`Missing translation '${e}'`));
    const a = e.split("."),
      t = a[a.length - 1] || " ";
    return t.charAt(0).toUpperCase() + t.slice(1)
  };
exports.t = t;
