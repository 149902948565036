"use strict";
Object.defineProperty(exports, "__esModule", {
  value: !0
}), exports.FigureElement = void 0;
const mobx_state_tree_1 = require("mobx-state-tree"),
  shape_model_1 = require("./shape-model");
exports.FigureElement = shape_model_1.Shape.named("Figure").props({
  type: "figure",
  subType: "rect",
  fill: "rgb(0, 161, 255)",
  dash: mobx_state_tree_1.types.array(mobx_state_tree_1.types.number),
  strokeWidth: 0,
  stroke: "rgba(98, 197, 255, 1)",
  cornerRadius: 0
});
