"use strict";
Object.defineProperty(exports, "__esModule", {
  value: !0
}), exports.selectSvg = void 0;
const image_1 = require("../utils/image"),
  selectSvg = async ({
    src: e,
    droppedPos: t,
    targetElement: i,
    store: s
  }) => {
    var r;
    if (i && "image" === i.type && i.contentEditable) return void i.set({
      clipSrc: e
    });
    if (i && "video" === i.type && i.contentEditable) return void i.set({
      clipSrc: e
    });
    const {
      width: l,
      height: o
    } = await (0, image_1.getImageSize)(e), c = ((null == t ? void 0 : t.x) || s.width / 2) - l / 2, d = ((null == t ? void 0 : t.y) || s.height / 2) - o / 2;
    null === (r = s.activePage) || void 0 === r || r.addElement({
      type: "svg",
      width: l,
      height: o,
      x: c,
      y: d,
      src: e
    })
  };
exports.selectSvg = selectSvg;
