"use strict";

function degToRad(t) {
  return t / 180 * Math.PI
}

function getCorner(t, e, n, o, r) {
  const a = Math.sqrt(n * n + o * o);
  r += Math.atan2(o, n);
  return {
    x: t + a * Math.cos(r),
    y: e + a * Math.sin(r)
  }
}

function getClientRect(t) {
  const {
    x: e,
    y: n,
    width: o,
    height: r
  } = t, a = degToRad(t.rotation), i = getCorner(e, n, 0, 0, a), x = getCorner(e, n, o, 0, a), h = getCorner(e, n, o, r, a), s = getCorner(e, n, 0, r, a), g = Math.min(i.x, x.x, h.x, s.x), d = Math.min(i.y, x.y, h.y, s.y), c = Math.max(i.x, x.x, h.x, s.x), u = Math.max(i.y, x.y, h.y, s.y);
  return {
    x: g,
    y: d,
    width: c - g,
    height: u - d,
    minX: g,
    minY: d,
    maxX: c,
    maxY: u
  }
}

function getTotalClientRect(t) {
  const e = t.map((t => getClientRect(t))),
    n = Math.min(...e.map((t => t.minX))),
    o = Math.min(...e.map((t => t.minY))),
    r = Math.max(...e.map((t => t.maxX))),
    a = Math.max(...e.map((t => t.maxY)));
  return {
    x: n,
    y: o,
    width: r - n,
    height: a - o,
    minX: n,
    minY: o,
    maxX: r,
    maxY: a
  }
}

function getCenter(t) {
  const e = degToRad(t.rotation || 0);
  return {
    x: t.x + t.width / 2 * Math.cos(e) + t.height / 2 * Math.sin(-e),
    y: t.y + t.height / 2 * Math.cos(e) + t.width / 2 * Math.sin(e)
  }
}

function rotateAroundPoint(t, e, n) {
  const o = degToRad(e),
    r = n.x + (t.x - n.x) * Math.cos(o) - (t.y - n.y) * Math.sin(o),
    a = n.y + (t.x - n.x) * Math.sin(o) + (t.y - n.y) * Math.cos(o);
  return Object.assign(Object.assign({}, t), {
    rotation: t.rotation + e,
    x: r,
    y: a
  })
}

function rotateAroundCenter(t, e) {
  return rotateAroundPoint(t, e, getCenter(t))
}
Object.defineProperty(exports, "__esModule", {
  value: !0
}), exports.rotateAroundCenter = exports.rotateAroundPoint = exports.getCenter = exports.getTotalClientRect = exports.getClientRect = exports.degToRad = void 0, exports.degToRad = degToRad, exports.getClientRect = getClientRect, exports.getTotalClientRect = getTotalClientRect, exports.getCenter = getCenter, exports.rotateAroundPoint = rotateAroundPoint, exports.rotateAroundCenter = rotateAroundCenter;
