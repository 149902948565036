"use strict";
var __createBinding = this && this.__createBinding || (Object.create ? function(e, t, n, i) {
    void 0 === i && (i = n);
    var o = Object.getOwnPropertyDescriptor(t, n);
    o && !("get" in o ? !t.__esModule : o.writable || o.configurable) || (o = {
      enumerable: !0,
      get: function() {
        return t[n]
      }
    }), Object.defineProperty(e, i, o)
  } : function(e, t, n, i) {
    void 0 === i && (i = n), e[i] = t[n]
  }),
  __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function(e, t) {
    Object.defineProperty(e, "default", {
      enumerable: !0,
      value: t
    })
  } : function(e, t) {
    e.default = t
  }),
  __importStar = this && this.__importStar || function(e) {
    if (e && e.__esModule) return e;
    var t = {};
    if (null != e)
      for (var n in e) "default" !== n && Object.prototype.hasOwnProperty.call(e, n) && __createBinding(t, e, n);
    return __setModuleDefault(t, e), t
  };
Object.defineProperty(exports, "__esModule", {
  value: !0
}), exports.htmlToCanvas = exports.detectSize = exports.isContentWrapping = exports.prerenderFont = exports.resetStyleContent = void 0;
const rasterizeHTML = __importStar(require("rasterizehtml")),
  fonts_1 = require("./fonts"),
  canvas_1 = require("./canvas");
exports.resetStyleContent = "\n  p {\n    margin: 0;\n    padding: 0;\n    word-wrap: break-word; /* Required for Firefox */\n    /* a text may have several spaces, we need to preserve them */\n    white-space: pre-wrap;\n  }\n  ul, ol {\n    list-style-position: outside;\n    margin: 0;\n    padding: 0;\n    display: inline-block;\n    text-align: left;\n    width: 100%;\n  }\n  li {\n    margin-left: 1.7em;\n    padding-left: 0.5em; /* This should match the margin-left value */\n  }\n  /* Add resets */\n  ul {\n    list-style-type: disc;\n  }\n  ol {\n    list-style-type: decimal;\n  }\n  \n";
const resetStyle = `\n<style>\n  html, body {\n    padding: 0;\n    margin: 0;\n  }\n  ${exports.resetStyleContent}\n</style>\n`;
let hidden;
const getHiddenElement = () => (hidden || (hidden = document.createElement("div"), hidden.id = "polotno-hidden-do-not-touch", hidden.style.overflow = "hidden", hidden.style.position = "relative", document.body.appendChild(hidden), hidden.innerHTML = `<style>#polotno-hidden-do-not-touch {${exports.resetStyleContent}}</style>`), hidden),
  prerenderCache = new Map,
  isFirefox = navigator.userAgent.includes("Firefox"),
  prerenderFont = async e => {
    if (prerenderCache.has(e)) return prerenderCache.get(e);
    const t = (async () => {
      const t = `<div style="font-family: ${e}; font-size: 100px;">${e}<strong>${e}</strong></div>`,
        n = document.createElement("div");
      n.innerHTML = t, n.style.visibility = "hidden", n.style.position = "absolute";
      const i = getHiddenElement();
      i.appendChild(n);
      const o = isFirefox ? 180 : 50;
      await new Promise((e => setTimeout(e, o))), i.removeChild(n)
    })();
    return prerenderCache.set(e, t), t
  };

function isContentWrapping({
  html: e
}) {
  const t = document.createElement("div");
  t.innerHTML = e, t.style.display = "inline-block", t.style.position = "fixed", t.style.top = "0px", t.style.left = "0px", t.style.zIndex = "1000", t.style.whiteSpace = "nowrap", t.style.visibility = "hidden";
  const n = getHiddenElement();
  n.appendChild(t);
  const i = t.textContent,
    o = t.childNodes[0],
    s = i.split(/\s+/);
  let r = !1;
  for (let e = 0; e < s.length; e++) {
    const n = s[e];
    if (o.textContent = n, r = o.scrollWidth > t.clientWidth, r) break
  }
  return n.removeChild(t), r
}

function detectSize(e) {
  e.indexOf("Avellino");
  const t = document.createElement("div");
  t.innerHTML = e, t.style.display = "inline-block", t.style.position = "fixed", t.style.top = "0px", t.style.left = "0px", t.style.zIndex = "1000";
  const n = getHiddenElement();
  n.appendChild(t);
  const i = t.getBoundingClientRect();
  return n.removeChild(t), {
    width: i.width,
    height: i.height
  }
}
exports.prerenderFont = prerenderFont, exports.isContentWrapping = isContentWrapping, exports.detectSize = detectSize;
const cacheBucket = {};

function getFontFaceRules(e) {
  const t = [];
  for (const n of document.styleSheets) try {
    for (const i of n.cssRules) i instanceof CSSFontFaceRule && i.style.fontFamily.replace(/['"]/g, "") === e && t.push(i)
  } catch (e) {
    console.warn(`Could not access stylesheet: ${n.href}`, e)
  }
  return t
}
const stylesCache = {},
  getStyles = e => {
    if (stylesCache[e]) return stylesCache[e];
    const t = getFontFaceRules(e).filter((t => t.style.fontFamily.replace(/['"]/g, "") === e)).map((e => ({
      src: e.style.src,
      fontStyle: e.style.fontStyle || "normal",
      fontWeight: e.style.fontWeight || "normal"
    })));
    return stylesCache[e] = t, t
  };
async function htmlToCanvas({
  html: e,
  width: t,
  height: n,
  fontFamily: i,
  padding: o,
  font: s,
  pixelRatio: r
}) {
  if ("Arial" !== i && !s) {
    const t = (0, fonts_1.getGoogleFontsVariants)();
    e += `<link type="text/css" href="https://fonts.googleapis.com/css?family=${i}:${t}" rel="stylesheet">`
  }
  if (s) {
    const t = s.styles || (s.url ? [{
      src: `url("${s.url}")`
    }] : getStyles(i));
    e += "<style>", t.forEach((t => {
      e += `\n      @font-face {\n        font-family: '${i}';\n        src: ${t.src};\n        font-style: ${t.fontStyle||"normal"};\n        font-weight: ${t.fontWeight||"normal"};\n      }\n    `
    })), e += "</style>"
  }
  e += resetStyle;
  const l = document.createElement("canvas"),
    a = await rasterizeHTML.drawHTML(`<div style="padding: ${o}px;">${e}</div>`, l, {
      width: t + 2 * o,
      height: n + 2 * o,
      cacheBucket: cacheBucket
    });
  (0, canvas_1.trySetCanvasSize)(l, (t + 2 * o) * r, (n + 2 * o) * r);
  const d = l.getContext("2d");
  return null == d || d.drawImage(a.image, 0, 0, l.width, l.height), l
}
exports.htmlToCanvas = htmlToCanvas;
